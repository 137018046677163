<template>
    <div>
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>产品管理</el-breadcrumb-item>
            <el-breadcrumb-item>蜂计划</el-breadcrumb-item>
        </el-breadcrumb>
        <el-page-header @back="goBack"></el-page-header>
        <!-- 筛选 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span>订单筛选</span>
                </el-col>
            </el-row>
            <el-form
                :model="queryForm"
                class="filter_Form"
                label-width="80px"
                ref="queryFormRef"
            >
                <el-row :gutter="50">
                    <el-col :span="8">
                        <el-form-item label="文本" prop="text">
                            <el-input
                                v-model="queryForm.text"
                                placeholder="请输入（手机号、用户名）"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20" type="flex" justify="end">
                    <el-col :span="2">
                        <el-button
                            type="primary"
                            style="width: 100%"
                            @click="getbeePlanUserDataList"
                            >查询</el-button
                        >
                    </el-col>
                    <el-col :span="2">
                        <el-button
                            type="primary"
                            style="width: 100%"
                            @click="reset"
                            >重置</el-button
                        >
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <!-- 订单列表 -->
        <el-card class="list_card" style="margin-top: 20px">
            <el-row class="title_row">
                <el-col :span="12">
                    <div class="list_title">信息列表</div>
                </el-col>
            </el-row>
            <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="dataList"
                border
                stripe
            >
                <el-table-column label="序号" type="index"></el-table-column>
                <el-table-column label="手机号" prop="phone"></el-table-column>
                <el-table-column
                    label="用户名"
                    prop="userName"
                ></el-table-column>
                <el-table-column label="积分" prop="integral"></el-table-column>
                <el-table-column
                    label="补卡券"
                    prop="cardNumber"
                ></el-table-column>
                <el-table-column
                    label="生词打卡个数"
                    prop="errorWordNum"
                ></el-table-column>
                <el-table-column label="是否过期" prop="isExpire">
                    <template slot-scope="scope">
                        <el-tag
                            type="danger"
                            v-if="scope.row.isExpire === true"
                            size="small"
                            >已过期</el-tag
                        >
                        <el-tag type="success" v-else size="small"
                            >未过期</el-tag
                        >
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            icon="el-icon-edit"
                            size="small"
                            @click="showEditProductDialog(scope.row)"
                            >修改</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryForm.pageNum"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="queryForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </el-card>
        <el-dialog
            title="修改信息"
            :visible.sync="editPlanDialogVisible"
            @close="editPlanDialogClose"
            width="30%"
        >
            <el-form
                :model="editPlanForm"
                label-width="100px"
                ref="editPlanFormRef"
                :rules="editPlanFormRules"
            >
                <el-form-item label="补卡券数量" prop="cardNumber">
                    <el-input
                        v-model="editPlanForm.cardNumber"
                        placeholder="请输入"
                        type="number"
                    ></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editPlanDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="editPlan">修 改</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { beePlanUserDataList, updateFengPlanCard } from '@/http/api'
export default {
  data () {
    return {
      editPlanFormRules: {
        cardNumber: [{ required: true, message: '请输入', trigger: 'blur' }]

      },
      editPlanDialogVisible: false,
      editPlanForm: {
        cardNumber: 0
      },
      dataList: [],
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        text: ''
      },
      total: 0
    }
  },
  created () {
    this.getbeePlanUserDataList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // 重置搜索条件
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.getbeePlanUserDataList()
    },
    getbeePlanUserDataList () {
      beePlanUserDataList(this.queryForm).then((res) => {
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('err', err)
      })
    },
    showEditProductDialog (rowData) {
      this.editPlanForm.id = rowData.id
      this.editPlanForm.cardNumber = rowData.cardNumber
      this.editPlanDialogVisible = true
    },
    editPlanDialogClose () {
      this.$refs.editPlanFormRef.resetFields()
    },
    editPlan () {
      this.$refs.editPlanFormRef.validate(async valid => {
        updateFengPlanCard(this.editPlanForm).then((res) => {
          this.$message.success('修改成功')
          this.editPlanDialogVisible = false
          this.getbeePlanUserDataList()
        }).catch((err) => {
          console.log('err', err)
        })
      })
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList()
    }
  }
}
</script>

<style>
</style>
